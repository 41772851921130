<template>
  <v-row class="user-bio-panel">
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title
          v-touch="{
            left: () =>
              goToNext(projectData.projectIds, projectData.id, loading, {
                name: 'apps-project-view',
                param: 'id',
              }),
            right: () =>
              goToPrevious(projectData.projectIds, projectData.id, loading, {
                name: 'apps-project-view',
                param: 'id',
              }),
          }"
          class="justify-center flex-column"
        >
          <div class="d-flex justify-space-between align-center w-full mb-2">
            <v-btn
              icon
              color="primary"
              :disabled="!canGoToPrevious(projectData.projectIds, projectData.id) || loading"
              @click="
                goToPrevious(projectData.projectIds, projectData.id, loading, {
                  name: 'apps-project-view',
                  param: 'id',
                })
              "
              ><v-icon>{{ icons.mdiChevronLeft }}</v-icon></v-btn
            >
            <span class="text-center">{{ projectData.name }}</span>
            <v-btn
              icon
              color="primary"
              :disabled="!canGoToNext(projectData.projectIds, projectData.id) || loading"
              @click="
                goToNext(projectData.projectIds, projectData.id, loading, {
                  name: 'apps-project-view',
                  param: 'id',
                })
              "
            >
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
          </div>
          <v-chip
            label
            small
            color="primary"
            class="v-chip-light-bg primary--text font-weight-semibold text-capitalize ma-1 mb-2"
            >{{ statusText(projectData) }}</v-chip
          >
          <span class="d-flex justify-center align-center flex-wrap">
            <v-chip
              v-for="(tag, index) in projectData.tags"
              :key="index"
              label
              small
              color="info"
              outlined
              class="font-weight-semibold ma-1"
              v-text="tag.tag"
            />
          </span>
        </v-card-title>

        <v-card-text>
          <h2 v-t="'details'" class="text-xl font-weight-semibold mb-2" />

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('name') }}:</span>
              <span class="text--secondary">{{ projectData.name }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-1">
              <span>
                <span class="font-weight-medium me-2">{{ $t('description') }}:</span>
                <span class="text--secondary">{{ projectData.description }}</span>
              </span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('workload') }}:</span>
              <span class="text--secondary"
                >{{ projectData.allAssignees }}/{{ projectData.allPositionsDemand }} ({{ projectData.workload }}%)</span
              >
            </v-list-item>
          </v-list>

          <h2 v-t="'client'" class="text-xl font-weight-semibold mb-2 mt-4" />

          <v-divider></v-divider>
          <v-list>
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('client') }}:</span>
              <span class="text--secondary"
                >{{ projectData.clientName }}
                <v-icon color="primary" class="ml-2" small @click="goToClientDetailsPage(projectData.client)">{{
                  icons.mdiOpenInNew
                }}</v-icon>
              </span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('clientRegistrationName') }}:</span>
              <span class="text--secondary">{{ projectData.clientRegistrationName }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('clientStatus') }}:</span>
              <span class="text--secondary">{{ projectData.clientStatus }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center">
            <v-col cols="auto">
              <v-dialog v-model="isDeleteDialogOpen" max-width="650px" persistent>
                <template #activator="{ on, attrs }">
                  <v-btn color="error" v-bind="attrs" v-on="on" @click="isDeleteDialogOpen = true">
                    <v-icon class="me-2">{{ icons.mdiTrashCanOutline }}</v-icon>
                    <span v-t="'delete'" />
                  </v-btn>
                </template>
                <v-card class="pa-sm-10 pa-3">
                  <v-card-title v-t="'delete'" class="justify-center text-h5" />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="d-flex align-center justify-center">{{
                        $t('confirmationModalUndoneQuestion')
                      }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center mt-3">
                        <v-btn v-t="'discard'" outlined class="me-3" @click="isDeleteDialogOpen = false" />
                        <v-btn v-t="'delete'" color="error" @click="deleteProjectHandler" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="auto">
              <v-btn color="primary" @click="isBioDialogOpen = !isBioDialogOpen">
                <v-icon class="me-2">{{ icons.mdiPencilOutline }}</v-icon>
                <span v-t="'edit'" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

      <project-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :project-data.sync="projectData"
        :status-choices="statusChoices"
        :client-choices="clientChoices"
      ></project-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import { mdiTrashCanOutline, mdiPencilOutline, mdiOpenInNew, mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import { useRouter } from '@core/utils'
import ProjectBioEdit from './ProjectBioEdit.vue'
import projectUtils from '@/utils/projectUtils'
import navigationUtils from '@/utils/navigationUtils'

export default {
  components: {
    ProjectBioEdit,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { fetchStatusChoices, fetchClientChoices, statusText, statusChoices, clientChoices } = projectUtils()
    const isBioDialogOpen = ref(false)
    const isDeleteDialogOpen = ref(false)
    const { router } = useRouter()

    const { deleteProject } = useActions('staff', ['deleteProject'])
    const { projectData } = useState('staff', ['projectData'])

    const { canGoToNext, goToNext, canGoToPrevious, goToPrevious } = navigationUtils()

    const deleteProjectHandler = async () => {
      await deleteProject(projectData.value.id)
      router.push({ name: 'apps-project-list' })
    }

    const goToClientDetailsPage = (clientId) => {
      if (!clientId) return
      const route = router.resolve({ name: 'apps-client-view', params: { id: clientId } })
      window.open(route.href, '_blank')
    }

    onMounted(async () => {
      await fetchStatusChoices()
      await fetchClientChoices()
    })

    return {
      deleteProjectHandler,
      statusText,
      goToClientDetailsPage,
      canGoToNext,
      goToNext,
      canGoToPrevious,
      goToPrevious,

      projectData,
      statusChoices,
      clientChoices,
      isBioDialogOpen,
      isDeleteDialogOpen,
      icons: {
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiOpenInNew,
        mdiChevronLeft,
        mdiChevronRight,
      },
    }
  },
}
</script>
<style lang="scss">
.text--primary-link {
  color: var(--v-primary-base);
}
</style>
