<template>
  <div class="user-tab-security">
    <file-table
      :loading="loading"
      :files="localFiles"
      :file-types="fileTypes"
      handle-document-generation
      handle-types-and-valid-until
      handle-reminders
      :document-generator-data="{
        module: MODULE_PROJECTS,
        obj: projectData,
        target: projectData.name,
      }"
      :upload-file-callback="uploadProjectFiles"
      :disable-delete="!$can(ACTIONS.DELETE, PRIVATE.STAFF.PROJECTS_FILES)"
      :delete-file-callback="deleteProjectFile"
      :update-file-callback="updateProjectFile"
      :file-target-object="{ projectId: projectData.id }"
      :reminder-target-object="{ project: projectData.id }"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'

import _ from 'lodash'
import projectUtils from '@/utils/projectUtils'
import router from '@/router'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import documentTemplateUtils from '@/utils/templates/document/documentTemplateUtils'

import FileTable from '@/components/files-table/FileTable.vue'

export default {
  components: {
    FileTable,
  },
  setup() {
    const { uploadProjectFiles, deleteProjectFile, updateProjectFile, getProjectDetails } = useActions('staff', [
      'uploadProjectFiles',
      'deleteProjectFile',
      'updateProjectFile',
      'getProjectDetails',
    ])
    const { projectData } = useState('staff', ['projectData'])

    const { fetchFileTypes, fileTypes } = projectUtils()

    const { MODULE_PROJECTS } = documentTemplateUtils()

    const localFiles = ref([])
    const loading = ref(false)

    localFiles.value = _.cloneDeep(projectData.value.files)

    const refetchData = async () => {
      loading.value = true
      await getProjectDetails(router.currentRoute.params.id)
      localFiles.value = _.cloneDeep(projectData.value.files)
      await fetchFileTypes()
      loading.value = false
    }

    onMounted(async () => {
      await fetchFileTypes()
    })

    return {
      refetchData,
      uploadProjectFiles,
      deleteProjectFile,
      updateProjectFile,

      projectData,
      fileTypes,
      localFiles,
      loading,

      MODULE_PROJECTS,
      PRIVATE,
      ACTIONS,
    }
  },
}
</script>
<style scoped lang="scss"></style>
