<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <bread-crumbs
          :context="{
            projectName: `${projectData.name}`,
          }"
        />
        <project-bio-panel :loading="loading" />
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-tabs v-model="userTab" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ $t(`${tab.title}`) }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items id="user-tabs-content" :key="projectData.id" v-model="userTab" class="mt-5 pa-1" touchless>
          <v-tab-item>
            <project-tab-positions v-if="projectData.id" :key="componentTabKey" @refetch-data="fetchProjectData" />
          </v-tab-item>

          <v-tab-item>
            <project-timesheet-table :key="componentTabKey" />
          </v-tab-item>

          <v-tab-item>
            <project-tab-files :key="componentTabKey" />
          </v-tab-item>

          <v-tab-item>
            <project-tab-reminders v-if="userTab === 3" @refetch-data="fetchProjectData" />
          </v-tab-item>

          <v-tab-item>
            <project-tab-custom-fields :key="componentTabKey" />
          </v-tab-item>

          <v-tab-item>
            <project-tab-internal-notes v-if="userTab === 5" :key="componentTabKey" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAccountCancelOutline,
  mdiLockOutline,
  mdiBookEditOutline,
  mdiCalendarOutline,
  mdiBellOutline,
} from '@mdi/js'
import { useActions, useMutations, useState } from 'vuex-composition-helpers'
import { useRouter } from '@core/utils'
import router from '@/router'
// eslint-disable-next-line object-curly-newline
import ProjectBioPanel from './project-bio-panel/ProjectBioPanel.vue'
import ProjectTabInternalNotes from './project-tab-internal-notes/ProjectTabInternalNotes.vue'
import ProjectTabPositions from './project-tab-positions/ProjectTabPositions.vue'
import ProjectTabFiles from './project-tab-files/ProjectTabFiles.vue'
import ProjectTabCustomFields from './project-tab-custom-fields/ProjectTabCustomFields.vue'
import ProjectTimesheetTable from './project-tab-timesheets/ProjectTimesheetTable.vue'
import BreadCrumbs from '@/components/files-table/BreadCrumbs.vue'
import ProjectTabReminders from '@/views/apps/staff/projects/project-view/project-tab-reminders/ProjectTabReminders.vue'

export default {
  components: {
    BreadCrumbs,
    ProjectBioPanel,
    ProjectTimesheetTable,
    ProjectTabInternalNotes,
    ProjectTabPositions,
    ProjectTabFiles,
    ProjectTabCustomFields,
    ProjectTabReminders,
  },
  setup() {
    const tabs = [
      { icon: mdiAccountCancelOutline, title: 'positions' },
      { icon: mdiCalendarOutline, title: 'timesheet' },
      { icon: mdiLockOutline, title: 'files' },
      { icon: mdiBellOutline, title: 'reminders' },
      { icon: mdiBookEditOutline, title: 'customFields' },
      { icon: mdiAccountOutline, title: 'internalNote' },
    ]

    const userTab = ref(null)
    const loading = ref(false)

    const { getProjectDetails } = useActions('staff', ['getProjectDetails'])
    const { projectData } = useState('staff', ['projectData'])
    const { SET_PROJECT_DATA } = useMutations('staff', ['SET_PROJECT_DATA'])

    const componentTabKey = ref(0)
    const forceRerenderTab = () => {
      componentTabKey.value += 1
    }

    const fetchProjectData = async () => {
      loading.value = true
      await getProjectDetails(router.currentRoute.params.id)
      loading.value = false
      forceRerenderTab()
    }

    const { route } = useRouter()

    watch(
      () => route.value.params.id,
      async () => {
        await fetchProjectData()
      },
    )

    onMounted(async () => {
      await fetchProjectData()
    })

    onUnmounted(() => {
      SET_PROJECT_DATA({
        assignees: [],
      })
    })

    return {
      fetchProjectData,

      componentTabKey,

      tabs,
      userTab,
      projectData,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
