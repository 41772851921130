<template>
  <div>
    <upgrade-alert />
    <v-card class="mb-9" :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)">
      <v-card-title v-t="'filter'" />
      <v-card-text class="pb-5">
        <v-row>
          <v-col cols="auto" class="d-flex align-center">
            <v-row no-gutters>
              <v-col cols="auto" class="d-flex align-center">
                <v-switch
                  v-model="dayMonthSwitch"
                  :disabled="areChangingTimesheetParametersBlocked"
                  hide-details
                  :label="$t('dayMonthSwitch')"
                  class="mt-0 pt-0"
                  @change="fetchTimesheetDataHandler"
                />
                <v-btn
                  v-t="'today'"
                  color="primary"
                  class="mx-3"
                  :disabled="areChangingTimesheetParametersBlocked"
                  @click="setToday"
                />
              </v-col>
              <v-col cols="auto" class="d-flex align-center mt-3 mt-sm-0">
                <v-menu
                  v-if="!dayMonthSwitch"
                  ref="dailyDateMenuRef"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="activator">
                    <v-text-field
                      v-model="dailyDate"
                      :disabled="areChangingTimesheetParametersBlocked"
                      :label="$t('date')"
                      readonly
                      class="width--text-field-date-picker"
                      outlined
                      dense
                      v-bind="activator.attrs"
                      hide-details
                      v-on="activator.on"
                    />
                  </template>
                  <v-date-picker
                    v-model="dailyDate"
                    :first-day-of-week="1"
                    :locale="$i18n.locale"
                    :min="projectData.dateRangeStart"
                    :max="projectData.dateRangeEnd"
                    @change="saveDailyDate"
                  />
                </v-menu>
                <v-menu
                  v-else
                  ref="monthlyDateMenuRef"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="activator">
                    <v-text-field
                      v-model="monthlyDate"
                      :disabled="areChangingTimesheetParametersBlocked"
                      :label="$t('date')"
                      readonly
                      class="width--text-field-date-picker"
                      outlined
                      dense
                      v-bind="activator.attrs"
                      hide-details
                      persistent-hint
                      v-on="activator.on"
                    />
                  </template>
                  <v-date-picker
                    v-model="monthlyDate"
                    :first-day-of-week="1"
                    :locale="$i18n.locale"
                    type="month"
                    :min="projectData.dateRangeStart"
                    :max="projectData.dateRangeEnd"
                    @change="saveMonthlyDate"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="auto" offset-lg="1" :class="$vuetify.breakpoint.xs ? 'w-full' : 'ml-auto'">
            <v-btn
              v-t="'report'"
              color="info"
              outlined
              :block="$vuetify.breakpoint.xs"
              :disabled="areChangingTimesheetParametersBlocked"
              @click="isExportSidebarActive = true"
            />
            <v-btn
              v-t="'cancel'"
              color="primary"
              :block="$vuetify.breakpoint.xs"
              :class="$vuetify.breakpoint.xs ? 'my-2' : 'mx-2'"
              outlined
              @click="resetTimesheet"
            />
            <v-btn v-t="'save'" color="primary" :block="$vuetify.breakpoint.xs" @click="saveHandler" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-fade-transition>
              <v-alert v-if="areChangingTimesheetParametersBlocked" color="warning" text>
                <p v-t="'changingParametersBlocked'" class="font-weight-semibold mb-1" />
              </v-alert>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)">
      <v-card-title class="d-flex justify-space-between align-center">
        {{ !dayMonthSwitch ? $t('dailyTimesheet') : $t('monthlyTimesheet') }}
      </v-card-title>
      <v-data-table
        :headers="tableColumns"
        :items="!dayMonthSwitch ? assigneesDailyTimesheet : assigneesMonthlyTimesheet"
        sort-by="lastName"
        :item-key="'id'"
        :loading="loading"
        :hide-default-footer="true"
        :items-per-page="-1"
        group-by="group"
        disable-pagination
        disable-filtering
        :mobile-breakpoint="0"
      >
        <template #[`header.lastName`]="{ header }">
          {{ $t(header.text) }}
        </template>

        <template #[`header.hourlyRateCategoryDailyTimesheetSum`]="{ header }">
          <div class="text-center">{{ $t(header.text) }}</div>
        </template>

        <template #[`header.monthlyTimesheetAutoMode`]="{ header }">
          <div class="text-center">{{ $t(header.text) }}</div>
        </template>

        <template #body.prepend="{ headers }">
          <tr>
            <td v-for="(header, headerIndex) in headers" :key="`td--${headerIndex}`">
              <div v-if="header.value === 'lastName'" class="ms-3">{{ $t('sum') }}</div>
              <div v-else-if="header.value === 'monthlyTimesheetAutoMode'" class="text-center">
                <v-simple-checkbox
                  :value="areAllMonthlyTimesheetInAutoMode()"
                  :ripple="false"
                  color="primary"
                  :indeterminate="areAllMonthlyTimesheetAutoModeNotEqual()"
                  @click="handleGlobalAutoMode"
                />
              </div>
              <div v-else-if="header.value === 'hourlyRateCategoryDailyTimesheetSum'" class="text-center">
                {{ sumHourlyRateCategoryValuesAllColumns() }}
              </div>
              <div v-else class="start">{{ sumHourlyRateCategoryValuesForColumn(header.value) }}</div>
            </td>
          </tr>
        </template>

        <template #group.header="{ group, headers, toggle, isOpen }">
          <td v-for="(header, headerIndex) in headers" :key="`group-header--${headerIndex}`">
            <div v-if="header.value === 'lastName'">
              <v-icon v-if="isOpen" class="mr-2" @click="toggle">{{ icons.mdiChevronUpCircleOutline }}</v-icon>
              <v-icon v-else-if="!isOpen" class="mr-2" @click="toggle">{{ icons.mdiChevronDownCircleOutline }}</v-icon>
              <span class="font-weight-semibold">{{ getGroupText(group) }}</span>
            </div>
            <div v-else-if="header.value === 'hourlyRateCategoryDailyTimesheetSum'" class="text-center">
              {{ sumGroupHourlyRateCategoryValuesAllColumns(group) }}
            </div>
            <div v-else-if="header.value === 'monthlyTimesheetAutoMode'" class="text-center">
              <v-simple-checkbox
                :value="isGroupMonthlyTimesheetInAutoMode(group)"
                :ripple="false"
                color="primary"
                :indeterminate="isGroupMonthlyTimesheetAutoModeNotEqual(group)"
                @click="handleGroupAutoMode(group)"
              />
            </div>
            <div v-else>{{ sumGroupHourlyRateCategoryValuesForColumn(group, header.value) }}</div>
          </td>
        </template>

        <template v-for="(header, index) in tableColumns" #[`item.${header.value}`]="{ item }">
          <div v-if="header.value === 'lastName'" :key="index" class="d-flex align-center ms-3">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img v-if="item.avatar" :src="require(`@/assets/images/avatars/${item.avatar}`)"></v-img>
              <span v-else class="font-weight-medium">{{ avatarText(`${item.firstName} ${item.lastName}`) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'apps-employee-view', params: { id: item.employee } }"
                class="font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.firstName }} {{ item.lastName }}
              </router-link>
            </div>
          </div>
          <div v-else-if="header.value === 'hourlyRateCategoryDailyTimesheetSum'" :key="index" class="text-center">
            {{ sumHourlyRateCategoryValuesRow(item) }}
          </div>
          <div v-else-if="header.value === 'monthlyTimesheetAutoMode'" :key="index" class="text-center">
            <v-simple-checkbox
              v-model="item.monthlyTimesheet.autoMode"
              :ripple="false"
              color="primary"
              @click="autoModeMonthlyTimesheetHandler(item)"
            />
          </div>
          <div v-else :key="index">
            <v-text-field
              class="min-width--timesheet-input"
              :class="dayMonthSwitch && !item.monthlyTimesheet.autoMode ? 'mt-2' : ''"
              :value="getHourlyRateCategoryValue(header.value, item)"
              step=".01"
              outlined
              dense
              :disabled="dayMonthSwitch && item.monthlyTimesheet.autoMode"
              :hint="getHintText(header.value, item)"
              hide-details="auto"
              :persistent-hint="dayMonthSwitch"
              type="number"
              @change="updateHourlyRateCategoryValue($event, header.value, item)"
            />
          </div>
        </template>
      </v-data-table>
    </v-card>
    <project-timesheets-report
      v-model="isExportSidebarActive"
      :project-date-range-min="projectData.dateRangeStart"
      :project-date-range-max="projectData.dateRangeEnd"
    />
  </div>
</template>

<script>
import {
  mdiOpenInNew,
  mdiChevronRight,
  mdiChevronLeft,
  mdiChevronUpCircleOutline,
  mdiChevronDownCircleOutline,
} from '@mdi/js'
import { ref, onMounted, computed, watch, nextTick } from '@vue/composition-api'
import { avatarText, emptyValueFormatter } from '@core/utils/filter'
import _ from 'lodash'
import { useActions, useState } from 'vuex-composition-helpers'
import moment from 'moment'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import timesheetUtils from '@/utils/timesheetUtils'
import ProjectTimesheetsReport from '@/views/apps/staff/projects/project-view/project-tab-timesheets/ProjectTimesheetsReport.vue'
import UpgradeAlert from '@/components/UpgradeAlert.vue'

export default {
  components: {
    ProjectTimesheetsReport,
    UpgradeAlert,
  },
  setup() {
    // ********************** Core **********************
    const {
      getHourlyRatesCategoriesChoices,
      updateProjectAssigneesDailyTimesheets,
      updateProjectAssigneesMonthlyTimesheets,
      getProjectAssigneesDailyTimesheets,
      getProjectAssigneesMonthlyTimesheets,
    } = useActions('staff', [
      'getHourlyRatesCategoriesChoices',
      'updateProjectAssigneesDailyTimesheets',
      'updateProjectAssigneesMonthlyTimesheets',
      'getProjectAssigneesDailyTimesheets',
      'getProjectAssigneesMonthlyTimesheets',
    ])
    const { projectData } = useState('staff', ['projectData'])

    const loading = ref(false)
    const isExportSidebarActive = ref(false)

    const { dailyDateMenuRef, monthlyDateMenuRef, saveDailyDate, saveMonthlyDate } = timesheetUtils()

    // ********************** Daily Timesheet **********************

    const dailyDate = ref(moment().format('YYYY-MM-DD'))
    const assigneesDailyTimesheet = ref([])
    const frozenDailyAssigneesTimesheet = ref([])

    const areAssigneesDailyTimesheetChanged = computed(() => {
      return (
        !_.isEmpty(_.differenceWith(assigneesDailyTimesheet.value, frozenDailyAssigneesTimesheet.value, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(frozenDailyAssigneesTimesheet.value, assigneesDailyTimesheet.value, _.isEqual))
      )
    })

    const fetchDailyTimesheetData = async () => {
      loading.value = true
      assigneesDailyTimesheet.value = await getProjectAssigneesDailyTimesheets({
        projectId: projectData.value.id,
        date: dailyDate.value,
      })
      frozenDailyAssigneesTimesheet.value = Object.freeze(_.cloneDeep(assigneesDailyTimesheet.value))
      loading.value = false
    }

    const saveAssigneeDailyTimesheet = async () => {
      await updateProjectAssigneesDailyTimesheets({
        projectId: projectData.value.id,
        payload: _.differenceWith(assigneesDailyTimesheet.value, frozenDailyAssigneesTimesheet.value, _.isEqual).map(
          (assigneeDailyTimesheet) => ({
            id: assigneeDailyTimesheet.id,
            dailyTimesheetToWrite: assigneeDailyTimesheet.dailyTimesheet,
          }),
        ),
        date: dailyDate.value,
      })

      await fetchDailyTimesheetData()
    }

    // ********************** Monthly Timesheet **********************

    const monthlyDate = ref(moment().format('YYYY-MM'))
    const assigneesMonthlyTimesheet = ref([])
    const frozenAssigneesMonthlyTimesheet = ref([])

    const areAssigneesMonthlyTimesheetChanged = computed(() => {
      return (
        !_.isEmpty(
          _.differenceWith(assigneesMonthlyTimesheet.value, frozenAssigneesMonthlyTimesheet.value, _.isEqual),
        ) ||
        !_.isEmpty(_.differenceWith(frozenAssigneesMonthlyTimesheet.value, assigneesMonthlyTimesheet.value, _.isEqual))
      )
    })

    const fetchMonthlyTimesheetData = async () => {
      loading.value = true
      const [year, month] = monthlyDate.value.split('-')
      assigneesMonthlyTimesheet.value = await getProjectAssigneesMonthlyTimesheets({
        projectId: projectData.value.id,
        year,
        month,
      })
      frozenAssigneesMonthlyTimesheet.value = Object.freeze(_.cloneDeep(assigneesMonthlyTimesheet.value))
      loading.value = false
    }

    const getSummedDailyHourlyRateCategory = (hourlyRatesCategoryId, item) => {
      return item.summedDailyTimesheet.find(
        (summedDailyTimesheetItem) => summedDailyTimesheetItem.id === hourlyRatesCategoryId,
      )?.summedDailyValues
    }

    const saveAssigneeMonthlyTimesheet = async () => {
      const [year, month] = monthlyDate.value.split('-')
      await updateProjectAssigneesMonthlyTimesheets({
        projectId: projectData.value.id,
        payload: _.differenceWith(
          assigneesMonthlyTimesheet.value,
          frozenAssigneesMonthlyTimesheet.value,
          _.isEqual,
        ).map((assigneeMonthlyTimesheet) => ({
          id: assigneeMonthlyTimesheet.id,
          monthlyTimesheetToWrite: assigneeMonthlyTimesheet.monthlyTimesheet,
        })),
        year,
        month,
      })

      await fetchMonthlyTimesheetData()
    }

    // Monthly Timesheet Auto Mode common
    const autoModeMonthlyTimesheetHandler = (item) => {
      const [year, month] = monthlyDate.value.split('-')
      if (!item.monthlyTimesheet.year) {
        // eslint-disable-next-line no-param-reassign
        item.monthlyTimesheet.year = year
      }
      if (!item.monthlyTimesheet.month) {
        // eslint-disable-next-line no-param-reassign
        item.monthlyTimesheet.month = month
      }
      if (item.monthlyTimesheet.autoMode) {
        // eslint-disable-next-line no-param-reassign
        item.monthlyTimesheet.monthlyHourlyRateCategoryValues =
          item.monthlyTimesheet.monthlyHourlyRateCategoryValues.map((monthlyHourlyRateCategoryValue) => ({
            ...monthlyHourlyRateCategoryValue,
            hourlyRateCategoryValue: null,
          }))
      } else {
        item.summedDailyTimesheet.forEach((summedDailyTimesheetItem) => {
          const itemHourlyRatesCategoryValue = item.monthlyTimesheet?.monthlyHourlyRateCategoryValues.find(
            (hourlyRateCategoryValue) => hourlyRateCategoryValue.hourlyRateCategory === summedDailyTimesheetItem.id,
          )
          if (itemHourlyRatesCategoryValue) {
            itemHourlyRatesCategoryValue.hourlyRateCategoryValue = summedDailyTimesheetItem.summedDailyValues
          } else {
            item.monthlyTimesheet?.monthlyHourlyRateCategoryValues.push({
              hourlyRateCategory: summedDailyTimesheetItem.id,
              hourlyRateCategoryValue: summedDailyTimesheetItem.summedDailyValues,
            })
          }
        })
      }
    }
    const monthlyTimesheetInAutoModeHandler = (monthlyTimesheets) => {
      return monthlyTimesheets.every((assigneeMonthlyTimesheet) => assigneeMonthlyTimesheet.monthlyTimesheet.autoMode)
    }
    const monthlyTimesheetAutoModeNotEqualHandler = (monthlyTimesheets) => {
      return _.unionBy(monthlyTimesheets, 'monthlyTimesheet.autoMode').length > 1
    }

    // Monthly Timesheet Auto Mode All
    const areAllMonthlyTimesheetInAutoMode = () => {
      return monthlyTimesheetInAutoModeHandler(assigneesMonthlyTimesheet.value)
    }
    const areAllMonthlyTimesheetAutoModeNotEqual = () => {
      return monthlyTimesheetAutoModeNotEqualHandler(assigneesMonthlyTimesheet.value)
    }
    const handleGlobalAutoMode = () => {
      if (areAllMonthlyTimesheetInAutoMode()) {
        assigneesMonthlyTimesheet.value.forEach((assigneeMonthlyTimesheet) => {
          // eslint-disable-next-line no-param-reassign
          assigneeMonthlyTimesheet.monthlyTimesheet.autoMode = false
          autoModeMonthlyTimesheetHandler(assigneeMonthlyTimesheet)
        })
      } else {
        assigneesMonthlyTimesheet.value.forEach((assigneeMonthlyTimesheet) => {
          // eslint-disable-next-line no-param-reassign
          assigneeMonthlyTimesheet.monthlyTimesheet.autoMode = true
          autoModeMonthlyTimesheetHandler(assigneeMonthlyTimesheet)
        })
      }
    }

    // Monthly Timesheet Auto Mode Group
    const isGroupMonthlyTimesheetInAutoMode = (group) => {
      return monthlyTimesheetInAutoModeHandler(
        assigneesMonthlyTimesheet.value.filter((assigneeMonthlyTimesheet) => assigneeMonthlyTimesheet.group === group),
      )
    }
    const isGroupMonthlyTimesheetAutoModeNotEqual = (group) => {
      return monthlyTimesheetAutoModeNotEqualHandler(
        assigneesMonthlyTimesheet.value.filter((assigneeMonthlyTimesheet) => assigneeMonthlyTimesheet.group === group),
      )
    }
    const handleGroupAutoMode = (group) => {
      if (isGroupMonthlyTimesheetInAutoMode(group)) {
        assigneesMonthlyTimesheet.value
          .filter((assigneeMonthlyTimesheet) => assigneeMonthlyTimesheet.group === group)
          .forEach((assigneeMonthlyTimesheet) => {
            // eslint-disable-next-line no-param-reassign
            assigneeMonthlyTimesheet.monthlyTimesheet.autoMode = false
            autoModeMonthlyTimesheetHandler(assigneeMonthlyTimesheet)
          })
      } else {
        assigneesMonthlyTimesheet.value
          .filter((assigneeMonthlyTimesheet) => assigneeMonthlyTimesheet.group === group)
          .forEach((assigneeMonthlyTimesheet) => {
            // eslint-disable-next-line no-param-reassign
            assigneeMonthlyTimesheet.monthlyTimesheet.autoMode = true
            autoModeMonthlyTimesheetHandler(assigneeMonthlyTimesheet)
          })
      }
    }

    // ********************** Common Timesheet **********************

    const dayMonthSwitch = ref(false)
    const hourlyRatesCategories = ref([])

    const areChangingTimesheetParametersBlocked = computed(() => {
      if (!dayMonthSwitch.value) {
        return areAssigneesDailyTimesheetChanged.value
      }

      return areAssigneesMonthlyTimesheetChanged.value
    })

    const tableColumns = computed(() => {
      const tableBase = [
        {
          text: 'employee',
          value: 'lastName',
          sortable: true,
          groupable: false,
          class: 'ps-7 text-uppercase',
        },
      ]
      hourlyRatesCategories.value.forEach((hourlyRateCategory) => {
        tableBase.push({
          text: hourlyRateCategory.text,
          value: hourlyRateCategory.value,
          sortable: false,
          groupable: false,
        })
      })

      tableBase.push({
        text: 'sum',
        value: 'hourlyRateCategoryDailyTimesheetSum',
        sortable: false,
        groupable: false,
      })

      if (dayMonthSwitch.value) {
        tableBase.push({
          text: 'autoMode',
          value: 'monthlyTimesheetAutoMode',
          sortable: false,
          groupable: false,
          class: 'text-center',
        })
      }

      return tableBase
    })

    // Flatten Hourly Rate Category Values
    const flattenHourlyRateCategoryValues = () => {
      if (!dayMonthSwitch.value) {
        return _.flatMap(assigneesDailyTimesheet.value, ({ dailyTimesheet }) =>
          _.map(dailyTimesheet.dailyHourlyRateCategoryValues, (dailyHourlyRateCategoryValue) => ({
            ...dailyHourlyRateCategoryValue,
            valueToSum: _.round(dailyHourlyRateCategoryValue.hourlyRateCategoryValue, 2),
          })),
        )
      }

      const assigneesMonthlyTimesheetAutoMode = []
      const assigneeMonthlyTimesheetManualMode = []
      assigneesMonthlyTimesheet.value.forEach((assigneeMonthlyTimesheet) => {
        if (assigneeMonthlyTimesheet.monthlyTimesheet.autoMode) {
          assigneesMonthlyTimesheetAutoMode.push(assigneeMonthlyTimesheet)
        } else {
          assigneeMonthlyTimesheetManualMode.push(assigneeMonthlyTimesheet)
        }
      })

      return [
        ..._.flatMap(assigneesMonthlyTimesheetAutoMode, ({ summedDailyTimesheet }) =>
          _.map(summedDailyTimesheet, (monthlyHourlyRateCategoryValue) => ({
            valueToSum: _.round(monthlyHourlyRateCategoryValue.summedDailyValues, 2),
            hourlyRateCategory: monthlyHourlyRateCategoryValue.id,
          })),
        ),
        ..._.flatMap(assigneeMonthlyTimesheetManualMode, ({ monthlyTimesheet }) =>
          _.map(monthlyTimesheet.monthlyHourlyRateCategoryValues, (monthlyHourlyRateCategoryValue) => ({
            ...monthlyHourlyRateCategoryValue,
            valueToSum: _.round(monthlyHourlyRateCategoryValue.hourlyRateCategoryValue, 2),
          })),
        ),
      ]
    }
    const flattenGroupHourlyRateCategoryValues = (group) => {
      if (!dayMonthSwitch.value) {
        return _.flatMap(
          assigneesDailyTimesheet.value.filter((assigneeDailyTimesheet) => assigneeDailyTimesheet.group === group),
          ({ dailyTimesheet }) =>
            _.map(dailyTimesheet.dailyHourlyRateCategoryValues, (dailyHourlyRateCategoryValue) => ({
              ...dailyHourlyRateCategoryValue,
              valueToSum: _.round(dailyHourlyRateCategoryValue.hourlyRateCategoryValue, 2),
            })),
        )
      }

      const assigneesMonthlyTimesheetAutoMode = []
      const assigneeMonthlyTimesheetManualMode = []
      assigneesMonthlyTimesheet.value.forEach((assigneeMonthlyTimesheet) => {
        if (assigneeMonthlyTimesheet.group === group) {
          if (assigneeMonthlyTimesheet.monthlyTimesheet.autoMode) {
            assigneesMonthlyTimesheetAutoMode.push(assigneeMonthlyTimesheet)
          } else {
            assigneeMonthlyTimesheetManualMode.push(assigneeMonthlyTimesheet)
          }
        }
      })

      return [
        ..._.flatMap(assigneesMonthlyTimesheetAutoMode, ({ summedDailyTimesheet }) =>
          _.map(summedDailyTimesheet, (monthlyHourlyRateCategoryValue) => ({
            valueToSum: _.round(monthlyHourlyRateCategoryValue.summedDailyValues, 2),
            hourlyRateCategory: monthlyHourlyRateCategoryValue.id,
          })),
        ),
        ..._.flatMap(assigneeMonthlyTimesheetManualMode, ({ monthlyTimesheet }) =>
          _.map(monthlyTimesheet.monthlyHourlyRateCategoryValues, (monthlyHourlyRateCategoryValue) => ({
            ...monthlyHourlyRateCategoryValue,
            valueToSum: _.round(monthlyHourlyRateCategoryValue.hourlyRateCategoryValue, 2),
          })),
        ),
      ]
    }

    // Sum Hourly Rate Category Values for column
    const sumHourlyRateCategoryValuesForColumn = (hourlyRateCategoryId) => {
      return _.round(
        _.sumBy(flattenHourlyRateCategoryValues(), (item) =>
          item.hourlyRateCategory === hourlyRateCategoryId ? item.valueToSum : 0,
        ),
        2,
      )
    }
    const sumGroupHourlyRateCategoryValuesForColumn = (group, hourlyRateCategoryId) => {
      return _.round(
        _.sumBy(flattenGroupHourlyRateCategoryValues(group), (item) =>
          item.hourlyRateCategory === hourlyRateCategoryId ? item.valueToSum : 0,
        ),
        2,
      )
    }

    // Sum Hourly Rate Category Values for row
    const sumHourlyRateCategoryValuesRow = (item) => {
      if (dayMonthSwitch.value && item.monthlyTimesheet.autoMode) {
        const mappedSummedDailyTimesheet = item.summedDailyTimesheet.map((summedDailyTimesheetItem) => ({
          ...summedDailyTimesheetItem,
          summedDailyValues: _.round(summedDailyTimesheetItem.summedDailyValues, 2),
        }))

        return _.round(_.sumBy(mappedSummedDailyTimesheet, 'summedDailyValues'), 2)
      }
      const timesheetType = !dayMonthSwitch.value ? 'dailyTimesheet' : 'monthlyTimesheet'
      const hourlyRateCategoryValuesType = !dayMonthSwitch.value
        ? 'dailyHourlyRateCategoryValues'
        : 'monthlyHourlyRateCategoryValues'

      const mappedHourlyRateCategoryValues = item[timesheetType]?.[hourlyRateCategoryValuesType]?.map(
        (hourlyRateCategoryValue) => ({
          ...hourlyRateCategoryValue,
          hourlyRateCategoryValue: _.round(hourlyRateCategoryValue.hourlyRateCategoryValue, 2),
        }),
      )

      return _.round(_.sumBy(mappedHourlyRateCategoryValues, 'hourlyRateCategoryValue'), 2)
    }

    // Sum Hourly Rate Category Values for all columns
    const sumHourlyRateCategoryValuesAllColumnsHandler = (flattenValues) => {
      return _.round(_.sumBy(flattenValues, 'valueToSum'), 2)
    }
    const sumHourlyRateCategoryValuesAllColumns = () => {
      return sumHourlyRateCategoryValuesAllColumnsHandler(flattenHourlyRateCategoryValues())
    }
    const sumGroupHourlyRateCategoryValuesAllColumns = (group) => {
      const filteredA = assigneesDailyTimesheet.value.filter(
        (assigneeDailyTimesheet) => assigneeDailyTimesheet.group === group,
      )
      const a = _.flatMap(filteredA, ({ dailyTimesheet }) =>
        _.map(dailyTimesheet.dailyHourlyRateCategoryValues, (dailyHourlyRateCategoryValue) => ({
          ...dailyHourlyRateCategoryValue,
          valueToSum: _.round(dailyHourlyRateCategoryValue.hourlyRateCategoryValue, 2),
        })),
      )

      return sumHourlyRateCategoryValuesAllColumnsHandler(a)
    }

    //---------
    const getHourlyRateCategoryValue = (hourlyRatesCategoryId, item) => {
      if (dayMonthSwitch.value && item.monthlyTimesheet.autoMode) {
        return item.summedDailyTimesheet.find(
          (summedDailyTimesheetItem) => summedDailyTimesheetItem.id === hourlyRatesCategoryId,
        )?.summedDailyValues
      }
      const timesheetType = !dayMonthSwitch.value ? 'dailyTimesheet' : 'monthlyTimesheet'
      const hourlyRateCategoryValuesType = !dayMonthSwitch.value
        ? 'dailyHourlyRateCategoryValues'
        : 'monthlyHourlyRateCategoryValues'

      return item[timesheetType]?.[hourlyRateCategoryValuesType]?.find(
        (hourlyRateCategoryValue) => hourlyRateCategoryValue.hourlyRateCategory === hourlyRatesCategoryId,
      )?.hourlyRateCategoryValue
    }

    const updateHourlyRateCategoryValue = (event, hourlyRatesCategoryId, item) => {
      const timesheetType = !dayMonthSwitch.value ? 'dailyTimesheet' : 'monthlyTimesheet'
      const hourlyRateCategoryValuesType = !dayMonthSwitch.value
        ? 'dailyHourlyRateCategoryValues'
        : 'monthlyHourlyRateCategoryValues'

      const itemHourlyRatesCategoryValue = item[timesheetType]?.[hourlyRateCategoryValuesType].find(
        (hourlyRateCategoryValue) => hourlyRateCategoryValue.hourlyRateCategory === hourlyRatesCategoryId,
      )
      if (item.id && itemHourlyRatesCategoryValue) {
        // workaround to show correct value in input
        // when thousandth part is changed/added we're skipping it (floor method) so the value is unchanged in fact
        // that's why input is not re-rendered, and it's showing value with thousandth part (looks like it's not trimmed)

        // set not trimmed value
        itemHourlyRatesCategoryValue.hourlyRateCategoryValue = event

        // set trimmed value in nextTick to perform re-rendering
        nextTick(() => {
          itemHourlyRatesCategoryValue.hourlyRateCategoryValue = _.floor(event, 2)
        })
      } else {
        if (!dayMonthSwitch.value) {
          // eslint-disable-next-line no-param-reassign
          item[timesheetType].date = dailyDate.value
        } else {
          const [year, month] = monthlyDate.value.split('-')
          // eslint-disable-next-line no-param-reassign
          item[timesheetType].year = year
          // eslint-disable-next-line no-param-reassign
          item[timesheetType].month = month
        }
        item[timesheetType]?.[hourlyRateCategoryValuesType].push({
          hourlyRateCategory: hourlyRatesCategoryId,
          hourlyRateCategoryValue: _.floor(event, 2),
        })
      }
    }

    const setToday = () => {
      if (!dayMonthSwitch.value) {
        dailyDate.value = moment().format('YYYY-MM-DD')
      } else {
        monthlyDate.value = moment().format('YYYY-MM')
      }
    }

    const resetTimesheet = () => {
      if (!dayMonthSwitch.value) {
        assigneesDailyTimesheet.value = _.cloneDeep(frozenDailyAssigneesTimesheet.value)
      } else {
        assigneesMonthlyTimesheet.value = _.cloneDeep(frozenAssigneesMonthlyTimesheet.value)
      }
    }

    const fetchTimesheetDataHandler = async () => {
      if (!dayMonthSwitch.value) {
        assigneesMonthlyTimesheet.value = []
        frozenDailyAssigneesTimesheet.value = []
        await fetchDailyTimesheetData()
      } else {
        assigneesDailyTimesheet.value = []
        frozenAssigneesMonthlyTimesheet.value = []
        await fetchMonthlyTimesheetData()
      }
    }

    const getHintText = (headerValue, item) => {
      if (!dayMonthSwitch.value) {
        return ''
      }
      if (!item.monthlyTimesheet?.autoMode) {
        return String(emptyValueFormatter(getSummedDailyHourlyRateCategory(headerValue, item)))
      }

      return ''
    }

    const saveHandler = async () => {
      if (!dayMonthSwitch.value) await saveAssigneeDailyTimesheet()
      else await saveAssigneeMonthlyTimesheet()
    }

    const getGroupText = (group) => {
      return projectData.value.positions.find((position) => position.id === group).name
    }

    // ********************************************

    onMounted(async () => {
      hourlyRatesCategories.value = await getHourlyRatesCategoriesChoices()
      await fetchDailyTimesheetData()
    })

    watch(dailyDate, () => {
      fetchDailyTimesheetData()
    })

    watch(monthlyDate, () => {
      fetchMonthlyTimesheetData()
    })

    return {
      // ******** Core ********
      loading,
      projectData,
      isExportSidebarActive,

      avatarText,
      emptyValueFormatter,

      icons: {
        mdiOpenInNew,
        mdiChevronRight,
        mdiChevronLeft,
        mdiChevronUpCircleOutline,
        mdiChevronDownCircleOutline,
      },

      ACTIONS,
      PRIVATE,

      // ******** Daily Timesheet ********
      saveAssigneeDailyTimesheet,
      saveDailyDate,

      dailyDate,
      assigneesDailyTimesheet,
      dailyDateMenuRef,

      // ******** Monthly Timesheet ********
      getSummedDailyHourlyRateCategory,
      autoModeMonthlyTimesheetHandler,
      saveAssigneeMonthlyTimesheet,

      areAllMonthlyTimesheetInAutoMode,
      areAllMonthlyTimesheetAutoModeNotEqual,
      handleGlobalAutoMode,

      isGroupMonthlyTimesheetInAutoMode,
      isGroupMonthlyTimesheetAutoModeNotEqual,
      handleGroupAutoMode,
      saveMonthlyDate,

      assigneesMonthlyTimesheet,
      monthlyDate,
      monthlyDateMenuRef,

      // ******** Common Timesheet ********
      getHourlyRateCategoryValue,
      updateHourlyRateCategoryValue,

      sumHourlyRateCategoryValuesForColumn,
      sumGroupHourlyRateCategoryValuesForColumn,

      sumHourlyRateCategoryValuesAllColumns,
      sumGroupHourlyRateCategoryValuesAllColumns,

      sumHourlyRateCategoryValuesRow,

      setToday,
      resetTimesheet,
      fetchTimesheetDataHandler,
      getHintText,
      saveHandler,
      getGroupText,

      areChangingTimesheetParametersBlocked,
      tableColumns,
      dayMonthSwitch,
    }
  },
}
</script>
<style lang="scss" scoped>
.min-width--timesheet-input {
  min-width: 100px;
}

.width--text-field-date-picker {
  width: 10em;
}
</style>
