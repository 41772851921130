import { ref } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'

export default function timesheetUtils() {
  const dailyDateMenuRef = ref(null)
  const monthlyDateMenuRef = ref(null)
  const saveDateHandler = (menuRef, value) => {
    menuRef.save(value)
  }

  const saveDailyDate = (value) => {
    saveDateHandler(dailyDateMenuRef.value, value)
  }

  const saveMonthlyDate = (value) => {
    saveDateHandler(monthlyDateMenuRef.value, value)
  }

  return {
    saveDateHandler,
    saveDailyDate,
    saveMonthlyDate,

    dailyDateMenuRef,
    monthlyDateMenuRef,
  }
}
