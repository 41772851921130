<template>
  <!-- edit profile dialog -->
  <v-dialog v-model="isBioDialogOpen" max-width="650px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Edit project information </v-card-title>

      <v-card-text class="mt-5">
        <v-form ref="bioEditForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="projectDataLocal.name"
                outlined
                dense
                maxlength="150"
                hide-details="auto"
                :label="$t('name')"
                :placeholder="$t('name')"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="projectDataLocal.description"
                outlined
                auto-grow
                :label="$t('description')"
                :placeholder="$t('description')"
                hide-details="auto"
                rows="4"
                maxlength="1000"
              />
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="projectDataLocal.status"
                :label="$t('status', { postfix: '' })"
                :placeholder="$t('status', { postfix: '' })"
                :items="statusChoices"
                outlined
                dense
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-combobox
                v-model="projectDataLocal.tags"
                :items="tagsChoices"
                :label="$t('tag')"
                :placeholder="$t('tag')"
                item-text="tag"
                item-value="id"
                multiple
                outlined
                dense
                hide-details
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    small
                    label
                    color="info"
                    outlined
                    class="font-weight-semibold disable-min-height--chip mt-1"
                  >
                    <span class="pr-3">{{ item === Object(item) ? item.tag : item }}</span>
                    <v-icon x-small @click="parent.selectItem(item)"> $delete </v-icon>
                  </v-chip>
                </template>
              </v-combobox></v-col
            >

            <v-col cols="12">
              <v-autocomplete
                v-model="projectDataLocal.client"
                :label="$t('client')"
                :placeholder="$t('client')"
                :items="clientChoices"
                outlined
                dense
                hide-details
                :rules="[validators.required]"
              >
                <template v-if="projectDataLocal.client" #append-outer>
                  <v-fade-transition leave-absolute>
                    <v-icon color="primary" @click="goToClientDetailsPage()">{{ icons.mdiOpenInNew }}</v-icon>
                  </v-fade-transition>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn
                v-t="'discard'"
                outlined
                color="secondary"
                class="me-3"
                @click.prevent="$emit('update:is-bio-dialog-open', false)"
              />
              <v-btn color="primary" type="submit" :loading="loading" :disabled="!valid || !areDataChanged">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required } from '@core/utils/validation'
import _ from 'lodash'
import { useRouter } from '@core/utils'
import { mdiOpenInNew } from '@mdi/js'
import projectUtils from '@/utils/projectUtils'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
    statusChoices: {
      type: Array,
      required: true,
    },
    clientChoices: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const valid = ref(true)
    const loading = ref(false)

    const bioEditForm = ref(null)

    const projectDataLocal = ref({})
    projectDataLocal.value = _.cloneDeep(props.projectData)

    const { updateProjectDetails } = useActions('staff', ['updateProjectDetails'])

    const {
      fetchTagChoices,

      tagsChoices,
    } = projectUtils()

    const handleFormSubmit = async () => {
      const isFormValid = bioEditForm.value.validate()
      if (!isFormValid) return

      loading.value = true

      // const { success } = await updateProjectDetails(projectDataLocal.value)

      const { success } = await updateProjectDetails({
        ...projectDataLocal.value,
        tags: projectDataLocal.value.tags.map((tag) => {
          return _.isObject(tag) ? tag : { tag }
        }),
      })
      loading.value = false

      if (success) {
        emit('update:is-bio-dialog-open', false)
      }
    }

    watch(
      () => props.isBioDialogOpen,
      async (newVal) => {
        if (newVal) {
          projectDataLocal.value = _.cloneDeep(props.projectData)
          await fetchTagChoices()
        }
      },
    )

    const areDataChanged = computed(() => {
      return (
        projectDataLocal.value.name !== props.projectData.name ||
        projectDataLocal.value.description !== props.projectData.description ||
        projectDataLocal.value.status !== props.projectData.status ||
        projectDataLocal.value.client !== props.projectData.client ||
        !_.isEmpty(_.differenceWith(projectDataLocal.value.tags, props.projectData.tags, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(props.projectData.tags, projectDataLocal.value.tags, _.isEqual))
      )
    })

    const { router } = useRouter()

    const goToClientDetailsPage = () => {
      if (!projectDataLocal.value.client) return
      const route = router.resolve({ name: 'apps-client-view', params: { id: projectDataLocal.value.client } })
      window.open(route.href, '_blank')
    }

    return {
      handleFormSubmit,

      goToClientDetailsPage,

      valid,
      loading,
      bioEditForm,
      projectDataLocal,
      areDataChanged,
      tagsChoices,

      validators: {
        required,
      },

      icons: {
        mdiOpenInNew,
      },
    }
  },
}
</script>
