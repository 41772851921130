<template>
  <div class="user-tab-security">
    <!-- change password -->
    <v-card class="mb-7">
      <v-card-title class="d-flex justify-space-between align-center"
        >{{ $t('positions') }}
        <v-btn small color="primary" class="me-3" @click.stop="isAddPositionDrawerVisible = true">
          <v-icon left>{{ icons.mdiPlus }}</v-icon>
          <span v-t="'addNewPosition'" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-for="(position, index) in projectData.positions" :key="`Position--${index}`" cols="12">
            <v-card outlined class="card--border-primary">
              <v-card-title>
                <v-row align-content="center" justify="space-between">
                  <v-col cols="9" sm="10">
                    {{ position.name }}
                  </v-col>
                  <v-col cols="auto" class="text-sm"> {{ position.allAssigneesCount }} / {{ position.demand }} </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-row no-gutters>
                  <v-col
                    v-for="(assignee, assigneeIndex) in position.currentAssignees"
                    :key="`Assignee--${assignee.id}-${assigneeIndex}`"
                    cols="auto"
                  >
                    <v-chip
                      label
                      small
                      color="info"
                      :to="{ name: 'apps-employee-view', params: { id: assignee.employee } }"
                      class="v-chip-light-bg info--text font-weight-semibold text-capitalize ma-1 mb-2"
                      ><span>{{ assignee.firstName }} {{ assignee.lastName }}</span>
                      <v-icon color="info" right>{{ icons.mdiChevronRight }}</v-icon></v-chip
                    >
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row align-content="center">
                  <v-col v-if="$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)" cols="3">
                    <span class="font-weight-bold me-2">{{ $t('futureCount') }}:</span>
                    <span class="text--secondary">{{ position.futureCount }}</span>
                  </v-col>
                  <v-col v-if="$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)" cols="3">
                    <span class="font-weight-bold me-2">{{ $t('pastCount') }}:</span>
                    <span class="text--secondary">{{ position.pastCount }}</span>
                  </v-col>
                  <v-spacer />
                  <v-col cols="auto">
                    <v-btn
                      right
                      small
                      depressed
                      color="primary"
                      :to="{ name: 'apps-project-position-view', params: { id: position.id } }"
                    >
                      <span v-t="'details'" />
                      <v-icon right>{{ icons.mdiChevronRight }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-navigation-drawer
      v-model="isAddPositionDrawerVisible"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
      app
      @input="(val) => !val && discardAdd()"
    >
      <div class="drawer-header d-flex align-center">
        <span v-t="'addPosition'" class="font-weight-semibold text-base text--primary" />
        <v-spacer></v-spacer>
        <v-btn icon small @click="isAddPositionDrawerVisible = false">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>
      <v-form ref="addPositionForm" @submit.prevent="confirmAdd">
        <v-card-text>
          <v-subheader v-t="'basicData'" />
          <v-text-field
            v-model="addPositionData.name"
            outlined
            dense
            maxlength="150"
            hide-details="auto"
            :label="$t('name')"
            :placeholder="$t('name')"
            :rules="[validators.required]"
            class="mb-3"
          ></v-text-field>
          <v-text-field
            v-model="addPositionData.demand"
            :label="$t('demand')"
            :placeholder="$t('demand')"
            outlined
            dense
            type="number"
            min="0"
            hide-details="auto"
            :rules="[validators.minPositiveValue]"
            class="mb-3"
          />
          <v-subheader v-t="'assigneeData'" />
          <v-autocomplete
            v-model="addPositionData.assignees"
            :items="employeesChoices"
            outlined
            chips
            clearable
            deletable-chips
            multiple
            hide-details="auto"
            :label="$t('employee')"
            :placeholder="$t('employee')"
            :rules="[validators.required]"
            class="mb-3"
          />
          <upgrade-alert-wrapper :form="false" class="mb-3">
            <template #blocked-features>
              <v-menu
                close-on-content-click
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
              >
                <template v-slot:activator="activator">
                  <v-text-field
                    v-model="addPositionData.dateStart"
                    :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                    :label="$t('assignmentDateStart')"
                    readonly
                    outlined
                    dense
                    clearable
                    v-bind="activator.attrs"
                    hide-details="auto"
                    :rules="[validators.dateRangeValidator(addPositionData.dateStart, addPositionData.dateEnd)]"
                    class="mb-3"
                    v-on="activator.on"
                  />
                </template>
                <v-date-picker v-model="addPositionData.dateStart" :first-day-of-week="1" :locale="$i18n.locale" />
              </v-menu>
              <v-menu
                :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="activator">
                  <v-text-field
                    v-model="addPositionData.dateEnd"
                    :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                    :label="$t('assignmentDateEnd')"
                    readonly
                    outlined
                    dense
                    clearable
                    v-bind="activator.attrs"
                    hide-details="auto"
                    :rules="[validators.dateRangeValidator(addPositionData.dateStart, addPositionData.dateEnd)]"
                    class="mb-3"
                    v-on="activator.on"
                  />
                </template>
                <v-date-picker v-model="addPositionData.dateEnd" :first-day-of-week="1" :locale="$i18n.locale" />
              </v-menu>
            </template>
          </upgrade-alert-wrapper>
        </v-card-text>
        <v-card-actions>
          <v-row class="overwritten-drawer-actions">
            <v-col cols="12" md="6">
              <v-btn color="primary" class="me-3" type="submit" block :loading="addLoading">
                {{ $t('add') }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn v-t="'discard'" color="secondary" outlined type="reset" block @click="discardAdd" />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import { mdiChevronRight, mdiPlus, mdiClose } from '@mdi/js'

import { required, minPositiveValue, dateRangeValidator } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import projectUtils from '@/utils/projectUtils'
import UpgradeAlertWrapper from '@/components/UpgradeAlertWrapper.vue'

export default {
  components: { UpgradeAlertWrapper },
  setup(_, { emit }) {
    const {
      getEmployeesChoices,

      employeesChoices,
    } = projectUtils()

    const { projectData } = useState('staff', ['projectData'])
    const { createPosition } = useActions('staff', ['createPosition'])

    const { router } = useRouter()

    onMounted(async () => {
      await getEmployeesChoices()
    })

    const goToEmployeeDetailsPage = (employee) => {
      if (!employee || !employee.id) return
      const route = router.resolve({ name: 'apps-employee-view', params: { id: employee.id } })
      window.open(route.href, '_blank')
    }

    const addLoading = ref(false)
    const isAddPositionDrawerVisible = ref(false)
    const addPositionForm = ref(null)
    const addPositionData = ref({
      name: '',
      demand: 0,
      assignees: [],
      dateStart: null,
      dateEnd: null,
    })

    const resetAddPositionData = () => {
      addPositionForm.value.reset()
      addPositionData.value = {
        name: '',
        demand: 0,
        assignees: [],
        dateStart: null,
        dateEnd: null,
      }
    }

    const confirmAdd = async () => {
      const isFormValid = addPositionForm.value.validate()
      if (!isFormValid) return

      addLoading.value = true
      const success = await createPosition({
        name: addPositionData.value.name,
        demand: addPositionData.value.demand,
        project: projectData.value.id,
        assignees: addPositionData.value.assignees.map((assignee) => ({
          employee: assignee,
          dateStart: addPositionData.value.dateStart,
          dateEnd: addPositionData.value.dateEnd,
        })),
      })
      if (success) {
        emit('refetch-data')
        isAddPositionDrawerVisible.value = false
        resetAddPositionData()
      }
      addLoading.value = false
    }

    const discardAdd = () => {
      isAddPositionDrawerVisible.value = false
      resetAddPositionData()
    }

    return {
      confirmAdd,
      discardAdd,

      projectData,
      addLoading,
      isAddPositionDrawerVisible,
      addPositionForm,
      addPositionData,
      employeesChoices,

      validators: {
        required,
        minPositiveValue,
        dateRangeValidator,
      },

      icons: {
        mdiChevronRight,
        mdiPlus,
        mdiClose,
      },

      goToEmployeeDetailsPage,
      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
